import S3 from 'aws-s3'
//import configJson from '../../config';
export default {
    s3Client(folder = '/') {
        var envConfig = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') ? process.env.NODE_ENV:'development';
        console.log("envConfigS3:",envConfig);
        console.log("process.env.NODE_ENV:",process.env.NODE_ENV);
        //var configS3 = configJson[envConfig].configS3;
        var configS3 = {
            "version": "---------",
            "bucketName": "-------------",
            "region": "----------------",
            "accessKeyId": "----------------",
            "secretAccessKey": "--------------------",
            "s3Url": "---------------"
        };//s3 ko dc sử dụng ở client nữa
        configS3.dirName = folder;
        return new S3(configS3);
    },
    fireBase() {
        return {
            // PROD
            apiKey: "AIzaSyAvgL53bXsCl48UPRWw3SNrZvTuJKavjOk",
            authDomain: "vali-f534e.firebaseapp.com",
            databaseURL: "https://vali-f534e-default-rtdb.firebaseio.com",
            projectId: "vali-f534e",
            storageBucket: "vali-f534e.appspot.com",
            messagingSenderId: "205796800431",
            appId: "1:205796800431:web:db0619549671cbb81fa15a",
            measurementId: "G-VBQ0F0Q5B3"
        }
    },
    googleMaps() {
        return  {
            key: "AIzaSyDoJpCn5CzM8Im8RYbEICIY0PweRAl4e20"
        }
    },
    calendarOptions() {
        return {
            vi: {
              firstDayOfWeek: 1,
              dayNames: ["Chủ nhật", "Thứ hai", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"],
              dayNamesShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
              dayNamesMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
              monthNames: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
              monthNamesShort: ["T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8", "T9", "T10", "T11", "T12"],
              today: 'Hôm nay',
              clear: 'Xóa',
              dateFormat: 'dd/mm/yy',
              weekHeader: 'Tuần'
            }
        }
    }
}